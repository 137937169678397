import React from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

// Components
import { PageMeta } from '@components/pageMeta';
import Layout from '@components/layout';

const ExploreSlider = loadable(() =>
  import('../components/pageModules/modules/exploreSlider'),
);
const ContactForm = loadable(() =>
  import('../components/portals/contact/contactForm'),
);
const Map = loadable(() => import('../components/portals/contact/map'));

const ContactPortalTemplate = ({ data: { page } }) => {
  const { seo } = page || {};

  return (
    <Layout>
      <PageMeta {...seo} />
      <Map {...page} />
      <ContactForm {...page} />
      <ExploreSlider className='pt-space-m pb-space-m' />
    </Layout>
  );
};

export default ContactPortalTemplate;

export const simpleContactPortalQuery = graphql`
  query standardContactPortalQuery($slug: String!) {
    page: sanityContactPortal(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      title
      mapText: _rawMapText(resolveReferences: { maxDepth: 10 })
      formText: _rawFormText(resolveReferences: { maxDepth: 10 })
    }
  }
`;
